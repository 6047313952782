.div-form-registro {
  background-image: url(../../../assets/bg.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  padding: 0 30%;
  background-repeat: no-repeat;
}

.div-form-registro .ant-space-item {
  text-align: center;
}

.div-form-registro a {
  color: rgba(0, 0, 0, 1);
  text-decoration: underline;
  font-weight: bold;
}

.div-form-registro button {
  height: 50px;
  border-radius: 50px;
  width: 210px;
}

.div-form-registro .btnRegistrarme {
  background: linear-gradient(
    360deg,
    rgba(150, 99, 35, 1) 0%,
    rgba(227, 190, 36, 1) 100%
  );
  border: none;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.div-form-registro .btnRegistrarme:hover {
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(227, 190, 36, 1);
  color: rgba(227, 190, 36, 1);
  font-size: 20px;
}

.div-form-registro .divBtnVolver {
  text-align: left;
}

.div-form-registro .btnVolver {
  margin: 3% auto 2% auto;
  background: transparent;
  border: 2px rgba(255, 255, 255, 1) solid;
  color: rgba(255, 255, 255, 1);
  font-size: small;
  font-weight: bold;
  cursor: pointer;
  height: 50px;
  border-radius: 50px;
  width: 150px;
}

.div-form-registro .btnVolver:hover {
  background: rgba(255, 255, 255, 1);
  border: 2px rgba(255, 255, 255, 1) solid;
  color: rgba(17, 51, 91, 1);
}

.div-form-registro form {
  background: rgba(255, 255, 255, 1);
  margin: 0 auto 5% auto !important;
  padding: 45px 60px !important;
  border-radius: 20px;
}

.div-form-registro .title {
  font-weight: bold;
  color: rgba(17, 51, 91, 1);
  text-align: center;
  margin: 10px auto;
}

.div-form-registro .subtitle {
  font-size: small;
  color: rgba(158, 158, 158, 1);
  text-align: center;
  margin: 20px auto;
}

.div-form-registro .title-section {
  font-weight: bold !important;
  color: rgba(17, 51, 91, 1) !important;
  text-align: left;
}

.div-form-registro .form-label {
  margin: unset !important;
  font-size: small !important;
}

@media screen and (width < 900px) {
  .div-form-registro {
    padding: 0 5%;
  }

  .div-form-registro form {
    /* width: 90%; */
    padding: 45px 20px !important;
  }
}

@media screen and (width > 900px) and (max-width: 1500px) {
  .div-form-registro {
    padding: 0 15%;
  }
  .div-form-registro form {
    /* width: 65%; */
    padding: 45px 40px !important;
  }
}
