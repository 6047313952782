.div-zona-usuario {
  background-image: url(../../../assets/bg.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  padding: 0 30%;
}

.div-zona-usuario .container {
  margin: 20px auto;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  border-radius: 20px;
}

.div-zona-usuario .ant-space {
  width: 100%;
  text-align: center;
}

.div-zona-usuario .title {
  font-weight: bold;
  color: rgba(17, 51, 91, 1);
  text-align: center;
  margin: 20px auto 0px auto;
  padding: 10px 0px 0px 0px;
}

.div-zona-usuario .subtitle {
  color: rgba(17, 51, 91, 1);
  text-align: center;
  margin: unset;
}

.div-zona-usuario .btnLogout {
  color: rgba(227, 190, 36, 1);
  border: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
}

@media screen and (width < 900px) {
  .div-zona-usuario {
    padding: 0 5%;
  }
}

@media screen and (width > 900px) and (max-width: 1500px) {
  .div-zona-usuario {
    padding: 0 10%;
  }
}
