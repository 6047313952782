@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
body {
  background: rgba(233, 233, 233, 1);
}
body,
.ant-modal-root,
.ant-form-item-explain-error {
  font-family: "Montserrat", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgba(34, 47, 75, 1);
}
::-webkit-scrollbar-track {
  background: rgba(244, 220, 43, 1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.ant-row {
  width: 100% !important;
}

.ant-picker {
  width: 100% !important;
}

.ant-picker,
.ant-input,
.ant-select-selector {
  font-size: 15px !important;
  border-radius: 25px !important;
  background: rgba(240, 240, 240, 1) !important;
}

.ant-input-lg {
  padding: 10px 20px !important;
  font-size: 15px !important;
  border-radius: 25px !important;
  background: rgba(240, 240, 240, 1) !important;
}

.ant-input-lg,
.ant-input-affix-wrapper {
  font-size: 15px !important;
  border-radius: 25px !important;
  background: rgba(240, 240, 240, 1) !important;
}

.ant-picker,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-selector:focus,
.ant-input-affix-wrapper,
.ant-select-focused,
.ant-input-focused,
.ant-input:focus {
  border-color: rgba(158, 158, 158, 1) !important;
  box-shadow: none !important;
  border-right-width: 1px !important;
  outline: 0 !important;
}

.ant-picker,
.ant-select-selector:hover,
.ant-input:hover {
  border-color: rgba(158, 158, 158, 1) !important;
  border-right-width: 1px !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset !important;
}

.ant-spin-dot-item {
  background-color: rgba(227, 190, 36, 1) !important;
}

.ant-tabs-ink-bar {
  background: rgba(34, 47, 75, 1) !important;
}

.ant-tabs-content-holder {
  padding: 20px 60px !important;
}

.ant-modal-content {
  border-radius: 20px !important;
}

.ant-btn-default {
  border-radius: 20px !important;
}

.ant-btn-default:hover {
  color: rgba(227, 190, 36, 1) !important;
  border-color: rgba(227, 190, 36, 1) !important;
}

.ant-btn-primary {
  border-radius: 20px !important;
  background: linear-gradient(
    360deg,
    rgba(150, 99, 35, 1) 0%,
    rgba(227, 190, 36, 1) 100%
  ) !important;
  border: none !important;
}

.ant-btn-primary:hover {
  opacity: 0.75 !important;
}

@media screen and (width < 900px) {
  .ant-tabs-content-holder {
    padding: 20px 10px !important;
  }
}

@media screen and (width > 900px) and (max-width: 1500px) {
}
