.div-cupones .title {
  font-weight: bold;
  color: rgba(217, 217, 217, 1);
  text-align: center;
  margin: 5px auto;
}

.div-cupones .subtitle {
  font-weight: bold;
  color: rgba(150, 99, 35, 1);
  text-align: center;
  margin: 5px auto;
}

.div-cupones form {
  text-align: center;
  width: 50%;
  margin: auto;
}

.div-cupones .btnEnviar {
  height: 40px;
  border-radius: 60px;
  width: 210px;
  background: linear-gradient(
    360deg,
    rgba(150, 99, 35, 1) 0%,
    rgba(227, 190, 36, 1) 100%
  );
  border: none;
  color: rgba(255, 255, 255, 1);
  font-size: small;
  font-weight: bold;
  cursor: pointer;
}

.div-cupones .btnEnviar:hover {
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(150, 99, 35, 1);
  color: rgba(150, 99, 35, 1);
}

.div-cupones hr {
  border: none;
  height: 2px;
  background: rgba(150, 99, 35, 1);
}

.div-cupones table {
  width: 100%;
  text-align: center;
}

.div-cupones table thead {
  font-size: medium;
  color: rgba(207, 207, 207, 1);
}

.div-cupones table tbody {
  background: rgba(247, 247, 247, 1);
  font-size: large;
}

.div-cupones table tbody td,
.div-cupones table thead th {
  padding: 10px;
}

.div-cupones table tbody .cupon {
  color: rgba(150, 99, 35, 1);
  font-weight: bold;
}

.div-cupones table tbody .estado-valido {
  color: rgba(76, 175, 80, 1);
  font-weight: bold;
}

.div-cupones table tbody .estado-no-valido {
  color: rgba(244, 67, 54, 1);
  font-weight: bold;
}

@media screen and (width < 900px) {
  .div-cupones form {
    margin: 10px auto;
    width: 90%;
  }

  .div-cupones table tbody {
    font-size: small;
  }
}
