.ant-layout-footer {
  display: flex;
  align-items: center;
  height: 115px;
  padding: 0px 5%;
  background: rgba(233, 233, 233, 1);
}

.col-logo-footer {
  display: flex;
  justify-content: left;
}

.col-links-footer {
  text-align: center;
  display: flex;
  justify-content: center;
}

.col-links-footer a {
  color: rgba(158, 158, 158, 1) !important;
}

.col-icons-footer {
  display: flex;
  justify-content: right;
}

.icon-footer {
  font-size: x-large;
  color: rgba(158, 158, 158, 1);
}

@media screen and (width < 900px) {
  .ant-layout-footer {
    height: 200px;
  }

  .col-logo-footer {
    padding: 10px;
    justify-content: center;
  }

  .col-links-footer {
    padding: 10px;
  }

  .col-icons-footer {
    padding: 10px;
    justify-content: center;
  }
}
