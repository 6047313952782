.ant-layout-header {
  display: flex;
  align-items: center;
  height: 85px;
  padding: 0px 5%;
  background: linear-gradient(
    180deg,
    rgba(63, 105, 185, 1) 0%,
    rgba(11, 27, 52, 1) 100%
  );
}

.col-link-header {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  text-align: right;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-link-header a {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
}

.col-logout-header {
  display: flex;
  justify-content: right;
  align-items: right;
}

@media screen and (width < 900px) {
  .ant-layout-header {
    height: auto;
  }

  .col-link-header {
    text-align: center;
    line-height: 15px;
    padding: 15px;
  }

  .col-logout-header {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (width > 900px) and (max-width: 1000px) {
  .ant-layout-header {
    height: auto;
  }
  .col-logout-header {
    justify-content: right;
    align-items: right;
  }
}
