.div-mis-datos .ant-row {
  margin: unset !important;
}

.div-mis-datos .ant-form-item {
  margin: 0px 0px 15px 0px !important;
}

.div-mis-datos .form-label {
  margin: unset !important;
  font-size: small !important;
}

.div-mis-datos .btnEditar {
  height: 40px;
  border-radius: 60px;
  width: 210px;
  background: linear-gradient(
    360deg,
    rgba(150, 99, 35, 1) 0%,
    rgba(227, 190, 36, 1) 100%
  );
  border: none;
  color: rgba(255, 255, 255, 1);
  font-size: small;
  font-weight: bold;
  cursor: pointer;
}

.div-mis-datos .btnEditar:hover {
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(150, 99, 35, 1);
  color: rgba(150, 99, 35, 1);
}

.div-mis-datos .col-btn-editar {
  text-align: center;
}
