.col-form {
  align-items: center;
  display: flex;
  background-image: url(../../../assets/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}

.col-form .ant-typography {
  margin: unset !important;
}

.col-form button {
  height: 50px;
  border-radius: 60px;
  width: 90%;
  font-size: medium;
}

.col-form form {
  width: 60% !important;
  margin: 5% auto !important;
}

.col-form .img-premio {
  width: 100%;
}

.col-form .title {
  font-weight: bold;
  color: rgba(17, 51, 91, 1);
}

.col-form .subtitle {
  font-weight: bold;
  color: rgba(158, 158, 158, 1);
}

.col-form .row-buttons {
  text-align: center;
}

.col-form .btnIngresar {
  background: linear-gradient(
    360deg,
    rgba(150, 99, 35, 1) 0%,
    rgba(227, 190, 36, 1) 100%
  );
  border: none;
  color: rgba(255, 255, 255, 1);

  font-weight: bold;
  cursor: pointer;
}

.col-form .btnRegistrarme {
  background: rgba(255, 255, 255, 1);
  border: 2px rgba(34, 47, 75, 1) solid;
  color: rgba(34, 47, 75, 1);

  font-weight: bold;
  cursor: pointer;
}

.col-form .btnIngresar:hover,
.col-form .btnRegistrarme:hover {
  opacity: 0.9;
}

.col-form .col-buttons {
  padding: 5px;
}

@media screen and (width < 900px) {
  .col-form .img-premio {
    width: 100%;
  }

  .col-form form {
    margin: 5% auto !important;
    width: 80% !important;
    text-align: center;
  }

  .col-form button {
    width: 60%;
    height: 50px;
  }
}

@media screen and (width > 900px) and (max-width: 1000px) {
  .col-form form {
    margin: 5% auto !important;
    width: 60% !important;
    text-align: center;
  }

  .col-form button {
    width: 40%;
    height: 50px;
  }
}
