.fab {
  position: fixed;
  background: rgba(13, 193, 67, 1);
  width: auto;
  height: 50px;
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  bottom: 90px;
}

.fab p {
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  margin: 5px;
  line-height: 12px;
  text-align: center;
}

.fab-icon {
  font-size: xx-large;
  color: rgba(255, 255, 255, 1);
}

.fab:hover {
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(13, 193, 67, 1);
}

.fab:hover .fab-icon,
.fab:hover p {
  color: rgba(13, 193, 67, 1);
}

@media screen and (width < 900px) {
  .fab {
    bottom: 15px;
    width: 50px;
  }
  .fab p {
    display: none;
  }
}
